import React, { type FC } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { MenuLinkItem } from '@atlassian/navigation-system/side-nav/menu-link-item';

import { SSRMouseEventWrapper, SSR_NAV_DRAFTS_BUTTON_METRIC } from '@confluence/browser-metrics';
import { useRouteUrl, useRouteDataRef } from '@confluence/route-manager';
import { HOME } from '@confluence/named-routes';
import { createLazyCallbackHook } from '@confluence/loadable/entry-points/lazy-callback';

import { EditIconComponent } from '../GlobalNavigationIcons';
import { i18n } from '../globalNavigationTranslations';

import { useGlobalItemVisibility } from './useGlobalItemVisibility';
import type { GlobalItemProps } from './globalItemProps';

const useLazyClickAnalytics = createLazyCallbackHook(
	async () =>
		(await import(/* webpackChunkName: "loadable-analyticsCallbacks" */ '../analyticsCallbacks'))
			.fireDraftsClickedAnalytics,
);

const onDraftSelector = {
	selector: (routeUrl: string | undefined) => {
		if (!routeUrl) {
			return false;
		}
		return routeUrl === HOME.toUrl({ view: 'drafts' });
	},
};

export const DraftsItem: FC<GlobalItemProps> = ({ isHidden, peekingId, setPeekingId }) => {
	const routeDataRef = useRouteDataRef();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const fireClickAnalytics = useLazyClickAnalytics(createAnalyticsEvent, routeDataRef);

	const isSelected = useRouteUrl(onDraftSelector);

	const shouldHide = useGlobalItemVisibility(
		'drafts',
		isSelected,
		isHidden,
		peekingId,
		setPeekingId,
	);

	if (shouldHide) {
		return null;
	}

	return (
		<SSRMouseEventWrapper metricName={SSR_NAV_DRAFTS_BUTTON_METRIC}>
			<MenuLinkItem
				href={HOME.toUrl({ view: 'drafts' })}
				elemBefore={EditIconComponent}
				isSelected={isSelected}
				onClick={fireClickAnalytics}
			>
				<FormattedMessage {...i18n.drafts} />
			</MenuLinkItem>
		</SSRMouseEventWrapper>
	);
};
